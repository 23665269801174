import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import SectionContact from '../components/section-contact'
import crafted from '../images/ud_crafted.svg'
import tri_shape from '../images/tri-group.svg'

export default function IndexPage({ data, location }) {
  const { wordpressPage } = data
  const { acf } = wordpressPage
  const {
    header,
    services,
    clients,
    process,
    testimonials,
    contact,
    seo,
    open_graph,
    twitter,
  } = acf

  return (
    <Layout location={location.pathname}>
      <Helmet
        title={seo.title}
        meta={[
          { name: 'description', content: seo.description },
          { name: 'og:title', content: open_graph.title },
          { name: 'og:description', content: open_graph.description },
          { name: 'og:image', content: open_graph.image.source_url },
          { name: 'og:url', content: 'https://www.modinfinity.com' },
          { name: 'twitter:title', content: twitter.title },
          { name: 'twitter:description', content: twitter.description },
          { name: 'twitter:image', content: twitter.image.source_url },
          { name: 'twitter:card', content: twitter.card_size },
        ]}
      />
      <section
        id="header-section"
        className="md:flex md:flex-row-reverse md:items-center"
      >
        <div className="md:w-1/2 lg:h-full p-4 sm:px-20 md:p-4 lg:p-10 xxl:p-24">
          <img
            src={crafted}
            alt="Illustration of a man interacting with a dark indigo software dashboard."
          />
        </div>
        <div className="md:w-1/2 py-4 sm:py-12 md:py-20 lg:py-24 xl:py-32 xxl:py-48 px-2 sm:px-20 md:pl-2 xl:px-4 xxl:px-10 md:pr-0 bg-indigo-base relative">
          <h1 className="mb-10 xxl:mb-24 font-black text-4xl lg:text-6xl xxl:text-7xl text-indigo-lightest text-center md:text-left relative">
            {header.title}
          </h1>
          <div className="flex justify-center md:justify-start relative">
            <Link
              to="/contact"
              className="transition-bg xsm:text-lg lg:text-xl font-semibold text-indigo-lightest hover:text-indigo-dark hover:bg-indigo-lightest border-2 border-indigo-lightest no-underline px-1 xsm:px-3 sm:px-4 py-3 xsm:py-4"
            >
              {header.call_to_action}
            </Link>
          </div>
        </div>
      </section>
      <section
        id="services-section"
        className="container mx-auto py-12 md:py-16 lg:py-24 px-2 xsm:px-4 md:px-8 md:flex md:flex-row-reverse relative"
      >
        <div className="md:w-1/2">
          <h2 className="pl-2 md:pl-0 text-3xl lg:text-5xl xxl:text-6xl text-indigo-darkest font-black border-l-8 md:border-l-0 md:border-r-8 border-indigo-base">
            {services.title}
          </h2>
          <p className="mt-10 xxl:mt-12 text-indigo-darkest text-xl font-light leading-normal">
            {services.copy}
          </p>
          <div className="mt-8 py-10 md:py-4 flex items-center justify-center md:justify-start relative">
            <Link
              to="/services"
              className="xxl:mr-12 p-2 xsm:p-4 font-semibold no-underline text-lg hover:bg-indigo-darkest text-indigo-darkest hover:text-indigo-lightest border-2 border-indigo-darkest transition-bg"
            >
              {services.call_to_action}
            </Link>
          </div>
        </div>
        <div className="md:w-1/2 mt-10">
          <div id="service-list-wrapper" className="">
            <div id="design-service-list-wrapper" className="">
              <h3 className="text-2xl lg:text-4xl xxl:text-5xl font-black text-indigo-darkest text-left">
                {services.design.title}
              </h3>
              <div className="w-24 h-2 bg-indigo-base" />
              <ul className="mt-4 lg:mt-6">
                {services.design.services.map(({ service }) => (
                  <li
                    key={service}
                    className="mt-4 text-lg lg:text-xl xxl:text-2xl font-bold text-indigo-darkest opacity-90"
                  >
                    {service}
                  </li>
                ))}
              </ul>
            </div>
            <div id="dev-service-list-wrapper" className="mt-8 xxl:mt-16">
              <h3 className="text-2xl lg:text-4xl xxl:text-5xl font-black text-indigo-darkest text-left">
                {services.development.title}
              </h3>
              <div className="w-24 h-2 bg-indigo-base" />
              <ul className="mt-4 lg:mt-6">
                {services.development.services.map(({ service }) => (
                  <li
                    key={service}
                    className="mt-4 text-lg lg:text-xl xxl:text-2xl font-bold text-indigo-darkest opacity-90"
                  >
                    {service}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        id="clients-section"
        className="relative py-12 md:py-24 lg:py-32 xxl:py-40 bg-indigo-lightest"
      >
        <div className="container mx-auto px-2 xsm:px-4 lg:px-8 md:flex md:flex-row-reverse md:items-center">
          <div className="md:w-1/2 sm:px-16 md:px-0">
            <Img
              fluid={clients.image.localFile.childImageSharp.fluid}
              className="shadow-lg"
              alt={clients.image.alt_text}
            />
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 md:pr-8">
            <h2 className="text-3xl lg:text-5xl xxl:text-6xl text-center md:text-left font-black text-indigo-darkest">
              {clients.title}
            </h2>
            <p className="my-6 lg:my-10 xxl:my-16 text-xl text-indigo-darkest font-light leading-normal">
              {clients.copy}
            </p>
            <div className="flex items-center justify-center md:justify-start">
              <Link
                to="/work"
                className="text-lg font-semibold no-underline hover:bg-indigo-darkest text-indigo-darkest hover:text-indigo-lightest border-2 border-indigo-darkest p-3 xsm:p-4 transition-bg"
              >
                {clients.call_to_action}
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="about-section" className="relative py-10 md:py-24 lg:py-32">
        <div className="container mx-auto px-2 xsm:px-4 md:px-8 md:flex md:items-center">
          <div
            id="process-shapes-wrapper"
            className="md:w-1/2 px-8 sm:px-24 md:px-10 lg:px-20"
          >
            <img
              src={tri_shape}
              alt="Decorative Modinfinity logo containing two triangles rotated inwards to form an infinity symbol"
            />
          </div>
          <div className="md:w-1/2 px-4 lg:px-10">
            <h2 className="text-3xl lg:text-5xl xxl:text-6xl text-center md:text-left font-black text-indigo-darkest">
              {process.title}
            </h2>
            <p className="my-6 lg:my-10 xxl:my-12 text-xl text-indigo-darkest font-light leading-normal">
              {process.copy}
            </p>
            <div className="flex items-center justify-center md:justify-start">
              <Link
                to="/about"
                className="text-lg font-semibold no-underline hover:bg-indigo-darkest text-indigo-darkest hover:text-indigo-lightest p-3 xsm:p-4 border-2 border-indigo-darkest transition-bg"
              >
                {process.call_to_action}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {testimonials.show_testimonials === 'yes' ? (
        <section id="testimonials-section" className="my-12 sm:my-20 xl:my-32">
          <h2 className="my-12 text-3xl md:text-4xl xl:text-5xl xxl:text-6xl text-indigo-darkest text-center font-black">
            {testimonials.title}
          </h2>
          <div id="testimonials-wrapper" className="xl:my-32">
            {testimonials.testimonial.map(
              ({ copy, name, organization, image }) => (
                <div
                  key={name}
                  className="md:w-5/6 lg:w-2/3 xl:w-3/5 xxl:w-1/2 my-8 xl:my-20 mx-8 md:mx-auto flex flex-col sm:flex-row items-center relative overflow-hidden shadow-md"
                >
                  <div className="w-full sm:w-80 md:w-3/5 mb-4 sm:mb-0 overflow-hidden">
                    <Img
                      fluid={image.localFile.childImageSharp.fluid}
                      alt={image.alt_text}
                    />
                  </div>
                  <div className="mb-4 px-2 sm:pl-10 flex flex-col items-center sm:items-start relative">
                    <div className="w-big sm:w-64 lg:w-80 xl:w-96 xxl:w-112 h-24 sm:h-64 lg:h-80 xl:h-96 xxl:w-112 -mt-8 sm:-mt-0 sm:-ml-16 lg:-ml-20 xl:-ml-32 bg-white testimonial-rotate block absolute" />
                    <h3 className="mb-1 font-sans font-semibold text-2xl relative">
                      {name}
                    </h3>
                    <span className="mb-2 font-bold text-lg relative">
                      {organization}
                    </span>
                    <p className="leading-normal font-light relative text-lg">
                      {copy}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </section>
      ) : (
        <div />
      )}
      <SectionContact contact={contact} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      acf {
        header {
          title
          call_to_action
        }
        services {
          title
          copy
          call_to_action
          design {
            title
            services {
              service
            }
          }
          development {
            title
            services {
              service
            }
          }
        }
        clients {
          title
          copy
          call_to_action
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt_text
          }
        }
        process {
          title
          copy
          call_to_action
        }
        testimonials {
          title
          show_testimonials
          testimonial {
            name
            organization
            copy
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 512) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt_text
            }
          }
        }
        contact {
          title
          copy
          call_to_action
        }
        seo {
          title
          description
        }
        open_graph {
          title
          description
          image {
            source_url
          }
        }
        twitter {
          title
          description
          card_size
          image {
            source_url
          }
        }
      }
    }
  }
`
